import React, { useEffect, useState } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import { getPackageReport } from "../../services/user";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { Sidebar } from "../../widgets/Sidebar";
import { Button } from "./partials/Button";
import toastr from "toastr";
export const PackageReport = () => {
  const [record, setRecord] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const columns = [
    {
      key: "Sr No.",
      text: "Sr. No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "packageId",
      text: "Package Name",
      className: "cust_name",
      align: "left",
      sortable: true,
      cell: (record) => {
        let packageLabel = "";
        switch (record.packageId) {
          case 1:
            packageLabel = "Basic";
            break;
          case 2:
            packageLabel = "Advance";
            break;
          case 3:
            packageLabel = "Premium";
            break;
          case 4:
            packageLabel = "Elite";
            break;
          default:
            packageLabel = "No package";
        }
        return <>{packageLabel}</>;
      },
    },

    {
      key: "packageAmount",
      text: "Package Amount",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{Number(record?.packageAmount).toFixed(4)}</>;
      },
    },
    {
      key: "created_at",
      text: "Date & Time",
      className: "Date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{moment(record.created_at).format("LLL")}</>;
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Download",
    no_data_text: "No user found!",
    // button: {
    //   print: true,
    //   csv: true,
    // },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Search in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  useEffect(() => {
    const getPackageData = async () => {
      const address = localStorage.getItem("wallet_address");
      const data = { address };

      const result = await getPackageReport(data);

      if (result?.status) {
        setRecord(result?.data);
      }
    };
    getPackageData();
  }, []);

  const handleSearch = async () => {
    if (startDate > endDate) {
      toastr.error("Start date cannot be later than end date");
      return;
    }
    const address = localStorage.getItem("wallet_address");
    if (startDate && endDate) {
      try {
        const token = localStorage.getItem("jwtToken");
        const data = { startDate, endDate, address };
        const res = await getPackageReport(data);

        if (res.status) {
          setRecord(res?.data);
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    } else {
      alert("Please select both start and end dates");
    }
  };
  const pageChange = (pageData) => {};

  return (
    <>
      <div className="dash_bar_man">
        <Sidebar />
        <div className="d-md-flex mb-2 mb-md-4 align-items-center">
          <Button />
          <h2 className="in_hadding order-md-1">Package Report</h2>
        </div>
       
        <div className="main-content">
          <div className="page-content">
            <div className="dash_box"> 
            <div className="d-flex mb-2 search_box_table">
            <input className="form-control form-control22 mr-3"
              type="date"
              onChange={(e) => setStartDate(e.target.value)}
              placeholder="Start Date"
            />
            <input className="form-control form-control22 mr-3"
              type="date"
              onChange={(e) => setEndDate(e.target.value)}
              placeholder="End Date"
            />
            <button className="btn btn_man_small" onClick={handleSearch}><i class="bi bi-search"></i></button>
            </div>
      
              <div className="product-list-outer">
                <ReactDatatable
                  config={config}
                  records={record}
                  columns={columns}
                  onPageChange={pageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
