import React from "react";

export const ContactUs = () => {
  return (
    <>
      <div
        className=" position-relative p60 overflow_hidden"
        id="token"
        data-scroll-index={4}
      >
        <div className="container">
          <div className="row">
            <div className=" col-md-10 m-auto">
              <div className data-aos="fade-up-right">
                <h2 className="text-center  mb-2 hadding" data-aos="fade-up">
                  Contact Us
                </h2>
                {/* <p className="text-center  mb-5 op_08" data-aos="fade-up">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  onsectetur adipiscing elit. Ut elit tellus,
                  <br /> luctus nec ullamcorper mattis, pulvinar dapibus leo.
                </p> */}
                <div className="row">
                  <div className=" col-md-6">
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Name"
                        name="name"
                        // defaultValue
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Email"
                        name="name"
                        // defaultValue
                        required
                      />
                    </div>
                  </div>
                  <div className=" col-md-6">
                    <div className="form-group">
                      <label>Message</label>
                      <textarea
                        className="form-control"
                        placeholder="Message"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn">
                 Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
