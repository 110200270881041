import React from "react";

export const Features = () => {
  return (
    <>
      <div>
        <div className="features_section bgc2  position-relative p60 overflow_hidden">
          <div className="container text-center">
            <h6 className=" small_hadding mb-2   position-relative">
              FEATURES
            </h6>
            <h2 className=" hadding mb-3  " data-aos="fade-up">
              AssetPlus Feature{" "}
            </h2>
            <div className="row">
              <div className="col-lg-3 col-md-6 mt-4" data-aos="zoom-in-right">
                <div className="single-features style-two">
                  <div className>
                    <img
                      decoding="async"
                      src="/assets/img/icon-1.png"
                      alt=" Payment Gateway"
                    />
                    <h3 className>Maximum Protection</h3>
                  </div>
                  <p>
                    {" "}
                    We use a reliable server that protects our website from any
                    DDoS attack and a secured connection by SSL service.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mt-4" data-aos="zoom-in-left">
                <div className="single-features style-two">
                  <div className>
                    <img
                      decoding="async"
                      src="/assets/img/icon-2.png"
                      alt="Interconnected Economies"
                    />
                    <h3 className>EASY TO USE</h3>
                  </div>
                  <p>
                    Grow your wealth with just a few clicks through our user-friendly interface, simple and secure transactions, accessible worldwide.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mt-4" data-aos="zoom-in-right">
                <div className="single-features style-two">
                  <div className>
                    <img
                      decoding="async"
                      src="/assets/img/icon-3.png"
                      alt=" Swap Aggregator"
                    />
                    <h3 className>Hight Profitability</h3>
                  </div>
                  <p>
                    {" "}
                    We offer the best strategy to generate maximum profits with
                    a minimum amount of your investment.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mt-4" data-aos="zoom-in-left">
                <div className="single-features style-two">
                  <div className>
                    <img
                      decoding="async"
                      src="/assets/img/icon-4.png"
                      alt="Incubation"
                    />
                    <h3 className>Professional Team</h3>
                  </div>
                  <p>
                    Our experienced team of highly qualified financial experts
                    and professional traders is always available to generate
                    more profit.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="features_section    position-relative p60 overflow_hidden">
          <div className="container  ">
            <div className="row">
              <div className="col-md-6  ">
                <h5
                  className=" small_hadding mb-3   position-relative"
                  data-aos="fade-up"
                >
                  WHY CHOOSE
                </h5>
                <h2 className=" hadding mb-3  " data-aos="fade-up">
                  Why Choose Asset Plus?
                </h2>
                <p data-aos="fade-up">
                  Join Asset Plus and be part of a dynamic, growth-oriented
                  organization with a diverse portfolio of businesses.
                </p>
                <h5 data-aos="fade-up">BENEFIT FROM:</h5>
                <ul className="list mt-3" data-aos="fade-up">
                  <li>
                    Diversified investment opportunities across crypto, Forex,
                    Stocks, and more
                  </li>
                  <li>Expert leadership and innovative approach</li>
                  <li>Strong potential for high returns on investment</li>
                  <li>Opportunity to be part of a global network</li>
                  <li>Transparent and secure investment process</li>
                </ul>
              </div>
              <div className="col-md-3  col-6" data-aos="zoom-in-left">
                <img
                  className="img-fluid br10"
                  src="/assets/img/why1.jpg"
                  alt="Incubation"
                />
              </div>
              <div className="col-md-3 col-6 " data-aos="zoom-in-left">
                <img
                  className="img-fluid br10"
                  src="/assets/img/why2.jpg"
                  alt="Incubation"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
