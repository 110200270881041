import React from "react";

export const Faq = () => {
  return <>
    <section className="faq p60 bgc2  position-relative overflow_hidden" data-scroll-index={5}>
  <div className="container ">
    <h2 className="text-center hadding mb-md-2" data-aos="fade-up">FAQ</h2>
    {/* <p className="text-center  mb-5 op_08" data-aos="fade-up">Lorem ipsum dolor sit amet, consectetur adipiscing elit onsectetur adipiscing elit. Ut elit tellus,<br /> luctus nec ullamcorper mattis, pulvinar dapibus leo.</p> */}
    <div className="row">
      <div className="col-md-8 m-auto">
        <div className="accordion md-accordion style-2" id="accordionEx" role="tablist" aria-multiselectable="true">
          <div className="card" data-aos="fade-up">
            <div className="card-header" role="tab" id="headingOne1">
              <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                What is Asset Plus?
              </a>
            </div>
            {/* Card body */}
            <div id="collapseOne1" className="collapse " role="tabpanel" aria-labelledby="headingOne1" data-parent="#accordionEx">
              <div className="card-body">
                Asset Plus is a diversified investment company offering innovative investment strategies and expertise to empower financial freedom.
              </div>
            </div>
          </div>
          <div className="card" data-aos="fade-up">
            <div className="card-header" role="tab" id="headingTwo2">
              <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2">
                What makes Asset Plus unique?
              </a>
            </div>
            <div id="collapseTwo2" className="collapse" role="tabpanel" aria-labelledby="headingTwo2" data-parent="#accordionEx">
              <div className="card-body">Our expert leadership, innovative strategies, and commitment to transparency and integrity set us apart.
              </div>
            </div>
          </div>
          <div className="card" data-aos="fade-up">
            <div className="card-header" role="tab" id="headingTwo3">
              <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo3" aria-expanded="false" aria-controls="collapseTwo3">
                What investment packages do you offer?
              </a>
            </div>
            <div id="collapseTwo3" className="collapse" role="tabpanel" aria-labelledby="headingTwo3" data-parent="#accordionEx">
              <div className="card-body"> We offer four tailored investment packages:
                <ul>
                  <li>Basic Package: Ideal for beginners, with a investment of $100-$999.</li>
                  <li>Advance Package: Suitable for growing portfolios, with a investment of $1,000-$4,999.</li>
                  <li>Premium Package: Designed for high-net-worth individuals, with a investment of $5,000-$9,999.</li>
                  <li>Elite Package: Exclusive for Elite investors, with a minimum investment of  $10,000.</li> 
                </ul>
              </div>
            </div>
          </div>
          <div className="card" data-aos="fade-up">
            <div className="card-header" role="tab" id="headingTwo4">
              <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo4" aria-expanded="false" aria-controls="collapseTwo4">
                How do I choose the right investment?
              </a>
            </div>
            <div id="collapseTwo4" className="collapse" role="tabpanel" aria-labelledby="headingTwo2" data-parent="#accordionEx">
              <div className="card-body"> Right invest based on individual goals, risk tolerance, and financial situation.
              </div>
            </div>
          </div>
          <div className="card" data-aos="fade-up">
            <div className="card-header" role="tab" id="headingTwo5">
              <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo5" aria-expanded="false" aria-controls="collapseTwo5">
                What are the risks associated with investing? </a>
            </div>
            <div id="collapseTwo5" className="collapse" role="tabpanel" aria-labelledby="headingTwo5" data-parent="#accordionEx">
              <div className="card-body">All investments carry risk. Our team helps mitigate risk through diversified portfolios and expert guidance.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  </>;
};
