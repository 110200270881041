import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
} from "@web3modal/ethers/react";
import { useAuth } from "../AuthContext";
import { binanceChainId, bscRpcUrl, explorerUrlBsc } from "../constant";
export const Sidebar = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { authenticated, wallet_address, logout } = useAuth();
  const projectId = "1d8e553b04494fc86b01065d081d9e93";

  useEffect(() => {
    if (authenticated && wallet_address) {
      const checkConnection = () => {
        if (!isConnected) {
          logout();
          window.location.href = "/register";
        } else if (address && address !== wallet_address) {
          logout();
          window.location.href = "/register";
        }
      };
      const timeout = setTimeout(checkConnection, 10000);
      return () => clearTimeout(timeout);
    }
  }, [address, isConnected, authenticated, wallet_address]);

  const mainnet = [
    {
      chainId: binanceChainId,
      name: "Binance Smart Chain",
      currency: "BNB",
      explorerUrl: explorerUrlBsc,
      rpcUrl: bscRpcUrl,
    },
  ];

  const metadata = {
    name: "assetplus",
    description: "ukc",
    url: "https://assetplus.com/",
    icons: ["https://assetplus.com/html/images/Logo.png"],
  };

  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: mainnet,
    projectId,
    enableAnalytics: true,
  });

  return (
    <>
      <div className="left_bar">
        <a href="/">
          <img
            src="/assets/img/logo.png"
            className="das_logo img-fluid mb-md-4 mt-md-2"
          />
        </a>
        <nav className="navbar2">
          <div className>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
            >
              <span className="icon-bar">
                <i class="bi bi-list"></i>
              </span>
            </button>
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav m-auto ">
                <li className="nav-item">
                  <Link
                    to={"/dashboard"}
                    className={
                      window.location.pathname === "/dashboard"
                        ? "active nav1"
                        : "nav1"
                    }
                  >
                    <i className="bi bi-grid" />
                    <span data-key="t-dashboards">Dashboard</span>
                  </Link>
                  {/* <a className="active nav1" href="/dashboard">
                    <i className="bi bi-grid" />
                    Dashbord
                  </a> */}
                </li>

                <li className="nav-item">
                  {/* <a className=" nav1" href="/package">
                    <i className="bi bi-box2" />
                    Package
                  </a> */}
                  <Link
                    to={"/package"}
                    className={
                      window.location.pathname === "/package"
                        ? "active nav1"
                        : "nav1"
                    }
                  >
                    <i className="bi bi-box2"></i>Package
                  </Link>
                </li>

                <li className="nav-item">
                  {/* <a className=" nav1" href="/package">
                    <i className="bi bi-box2" />
                    Package
                  </a> */}
                  <Link
                    to={"/get-package-report"}
                    className={
                      window.location.pathname === "/get-package-report"
                        ? "active nav1"
                        : "nav1"
                    }
                  >
                    <i className="bi bi-file-earmark-text"></i>Package Report
                  </Link>
                </li>
                <li className="nav-item">
                  {/* <a className=" nav1" href="/package">
                    <i className="bi bi-box2" />
                    Package
                  </a> */}
                  <Link
                    to={"/get-referral-report"}
                    className={
                      window.location.pathname === "/get-referral-report"
                        ? "active nav1"
                        : "nav1"
                    }
                  >
                    <i className="bi bi-share"></i>Referral Report
                  </Link>
                </li>

                <li className="nav-item">
                  {/* <a className=" nav1" href="/package">
                    <i className="bi bi-box2" />
                    Package
                  </a> */}
                  <Link
                    to={"/roi-report"}
                    className={
                      window.location.pathname === "/roi-report"
                        ? "active nav1"
                        : "nav1"
                    }
                  >
                    <i className="bi bi-card-text"></i>Roi Report
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={"/level-report"}
                    className={
                      window.location.pathname === "/level-report"
                        ? "active nav1"
                        : "nav1"
                    }
                  >
                    <i className="bi bi-card-text"></i>Level Report
                  </Link>
                </li>

                <li className="nav-item">
                  {/* <a className=" nav1" href="/package">
                    <i className="bi bi-box2" />
                    Package
                  </a> */}
                  <Link
                    to={"/team-report"}
                    className={
                      window.location.pathname === "/team-report"
                        ? "active nav1"
                        : "nav1"
                    }
                  >
                    <i className="bi bi-people"></i>Team
                  </Link>
                </li>
                <li className="nav-item">
                  {/* <a className=" nav1" href="/package">
                    <i className="bi bi-box2" />
                    Package
                  </a> */}
                  <Link
                    to={"/team-tree"}
                    className={
                      window.location.pathname === "/team-tree"
                        ? "active nav1"
                        : "nav1"
                    }
                  >
                    <i className="bi bi-diagram-3"></i>Genealogy
                  </Link>
                </li>
                <li className="nav-item">
                  {/* <a className=" nav1" href="/package">
                    <i className="bi bi-box2" />
                    Package
                  </a> */}
                  <Link
                    to={"/matching-report"}
                    className={
                      window.location.pathname === "/matching-report"
                        ? "active nav1"
                        : "nav1"
                    }
                  >
                    <i className="bi bi-diagram-3"></i>Reward Report
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to={"/withdrawal-report"}
                    className={
                      window.location.pathname === "/withdrawal-report"
                        ? "active nav1"
                        : "nav1"
                    }
                  >
                    <i className="bi bi-diagram-3"></i>Withdrawal Report
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      {/* <div className="d-flex mb-4">
        <h2>Dashboard</h2>
        <div className="img_box ml-auto d-inline-flex align-items-center">
          <h6 className="mr-4 mb-0">
            Your Wallet Address:{" "}
            <span className="color1 pl-2">
              <w3m-button balance="hide" />
            </span>{" "}
          </h6>
          <img alt className="max-h-full" src="/assets/img/user.png" />
        </div>
      </div> */}
    </>
  );
};
