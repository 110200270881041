import React from "react";

export const AboutUs = () => {
  return (
    <>
      <div id="scroll-to-top">
        <i class="bi bi-chevron-up fa-fw"></i>
      </div>
      <div class="about1 p50 position-relative  " data-scroll-index="2">
        <div class="container ">
        <div class="row">
            <div class="col-lg-12">
              <div className="five-fr">
                  <div class="d-flex align-items-center">
                    <span>
                    <img src="/assets/img/roi.svg" alt="alt" />
                    </span>
                    <div>
                      <p>ROI </p>
                    </div>
                  </div>

                  <div class="d-flex align-items-center">
                    <span>
                    <img src="/assets/img/direct-income.svg" alt="alt" />
                    </span>
                    <div>
                      <p>DIRECT REFERRAL INCOME</p>
                    </div>
                  </div>

                  <div class="d-flex align-items-center">
                    <span>
                     <img src="/assets/img/level-income.svg" alt="alt" />
                    </span>
                    <div>
                      <p> LEVEL INCOME</p>
                    </div>
                  </div>

                  <div class="d-flex align-items-center">
                    <span>
                    <img src="/assets/img/rank.svg" alt="alt" />
                    </span>
                    <div>
                      <p> RANK BONUS REWARDS</p>
                    </div>
                  </div>

                  <div class="d-flex align-items-center">
                    <span>
                    <img src="/assets/img/reward.svg" alt="alt" />
                    </span>
                    <div>
                      <p> OFFERS AND REWARDS</p>
                    </div>
                  </div>

                  

              </div>
              
            </div>
          </div>
          {/* <div class="row">
            <div class="col-md-3">
              <div class="d-flex align-items-center">
                <span>
                  <i class="bi bi-bar-chart-line"></i>
                </span>
                <div>
                  <p>Delivered projects </p>
                  <h2>153</h2>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="d-flex align-items-center">
                <span>
                  <i class="bi bi-laptop"></i>
                </span>
                <div>
                  <p>Projects successfull </p>
                  <h2>153</h2>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="d-flex align-items-center">
                <span>
                  <i class="bi bi-currency-dollar"></i>
                </span>
                <div>
                  <p> Generated revenue</p>
                  <h2>353</h2>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="d-flex align-items-center">
                <span>
                  <i class="bi bi-briefcase"></i>
                </span>
                <div>
                  <p> Assisted businesses</p>
                  <h2>253</h2>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div className="  p60  about" data-scroll-index={2}>
        <div className="container ">
          <div className="row">
            <div className="col-md-6 mb-4">
              <div className="about_left_img ">
                <img
                  className="position-absolute ab1"
                  src="/assets/img/how-can-i-help-you-2023-06-08-20-49-47-utc-min.jpg"
                  alt="alt"
                  data-aos="fade-right"
                />
                <img
                  className="position-absolute ab2"
                  src="/assets/img/in-the-modern-office-couple-are-getting-consulted-2023-03-22-11-17-16-utc-min.jpg"
                  alt="alt"
                  data-aos="fade-left"
                />
                <img
                  className="position-absolute ab3"
                  src="/assets/img/female-manager-consulting-client-with-documents-at-2023-09-04-18-00-27-utc-min.jpg"
                  alt="alt"
                  data-aos="fade-up"
                />
                <img
                  className="position-absolute ab4"
                  src="/assets/img/ab4.png"
                  alt="alt"
                  data-aos="fade-right"
                />
                <img
                  className="position-absolute ab5"
                  src="/assets/img/ab5.png"
                  alt="alt"
                  data-aos="fade-right"
                />
              </div>
            </div>
            <div className="col-md-6  pl-md-5" data-aos="zoom-in-left">
              <div className>
                <h5 className=" small_hadding mb-3   position-relative">
                  About us{" "}
                </h5>
                <h2 className=" hadding mb-3  " data-aos="fade-up">
                  About Assetplus
                </h2>
                <p>
                  Invest with us to tap into opportunities in crypto mining,
                  crypto trading, Forex trading, stocks, automobile, E-commerce,
                  and more. Benefit from our expertise, innovative approach, and
                  commitment to delivering strong returns.
                </p>
                <p>
                  Together, let's shape the future of business and create a
                  prosperous tomorrow.
                </p>
                <h4 className=" mt-4  mb-3">Our Values</h4>
                <ul className="elementor-icon-list-items">
                  <li className="elementor-icon-list-item">
                    <span className="elementor-icon-list-icon">
                      <i className="bi bi-circle" />{" "}
                    </span>
                    <span className="elementor-icon-list-text">Integrity</span>
                  </li>
                  <li className="elementor-icon-list-item">
                    <span className="elementor-icon-list-icon">
                      <i className="bi bi-circle" />{" "}
                    </span>
                    <span className="elementor-icon-list-text">Excellence</span>
                  </li>
                  <li className="elementor-icon-list-item">
                    <span className="elementor-icon-list-icon">
                      <i className="bi bi-circle" />
                    </span>
                    <span className="elementor-icon-list-text">
                      Transparency
                    </span>
                  </li>
                  <li className="elementor-icon-list-item">
                    <span className="elementor-icon-list-icon">
                      <i className="bi bi-circle" />
                    </span>
                    <span className="elementor-icon-list-text">
                      Customer-Centricity
                    </span>
                  </li>
                  <li className="elementor-icon-list-item">
                    <span className="elementor-icon-list-icon">
                      <i className="bi bi-circle" />
                    </span>
                    <span className="elementor-icon-list-text">
                      {" "}
                      Innovative spirit
                    </span>
                  </li>
                  <li className="elementor-icon-list-item">
                    <span className="elementor-icon-list-icon">
                      <i className="bi bi-circle" />
                    </span>
                    <span className="elementor-icon-list-text">
                      Collaborative teamwork
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
