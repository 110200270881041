import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
export const Header = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <>
      <header className="home overflow_hidden" id="home" data-scroll-index={1}>
        <div className="banner_content d-flex">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6  ">
                <h1 data-aos="fade-up">Empowering Your Financial Future </h1>
                <p data-aos="fade-up">
                  Join the cutting-edge of Assetplusnology and blockchain
                  innovation.
                </p>
                <div className="login_btns mt-4  mt-md-5">
                  <a href="/register" className="btn mb-4 mr-3">
                    <span>Get Started</span>
                  </a>
                  <a href="/Assetplus.pdf" target="_blank" className="btn mb-4">
                    <span>Business Plan</span>
                  </a>
                </div>
              </div>
              <div className="col-md-6 ">
                <img
                  src="/assets/img/hman.png"
                  alt="header-Logo"
                  className="img-fluid position-relative"
                />
                <img
                  src="/assets/img/hman_bg.png"
                  alt="header-Logo"
                  className="img-fluid position-absolute hman_bg"
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
