// import { createContext, useContext, useState, useEffect } from "react";

// const AuthContext = createContext();

// export const useAuth = () => {
//   return useContext(AuthContext);
// };

// export const AuthProvider = ({ children }) => {
//   const [authenticated, setAuthenticated] = useState(
//     localStorage.getItem("jwtToken") ? true : false
//   );
  


//   useEffect(() => {
//     if (localStorage.getItem("jwtToken")) {
//       setAuthenticated(true);
//     }
//   }, []);

//   const login = () => {
//     setAuthenticated(true);
//   };

//   const logout = () => {
//     setAuthenticated(false);
//     localStorage.clear();
//   };


//   return (
//     <AuthContext.Provider value={{ authenticated, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };


import { createContext, useContext, useState,useEffect } from "react";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(localStorage.getItem("jwtToken")?true:false);
  const [wallet_address, setWallet_address] = useState(localStorage.getItem("wallet_address")?localStorage.getItem("wallet_address"):"")

 useEffect(() => {
  if(localStorage.getItem("jwtToken")){
    setAuthenticated(true);
  }
 }, [authenticated])

  const login = () => {
    // Implement your authentication logic here
    setAuthenticated(true);
  };

  const logout = () => {
    // Implement your logout logic here
    setAuthenticated(false);
    setWallet_address("")
    localStorage.clear()
  };

  return (
    <AuthContext.Provider value={{ authenticated, login, logout,wallet_address,setWallet_address }}>
      {children}
    </AuthContext.Provider>
  );
};
