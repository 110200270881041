import React from "react";

export const Process = () => {
  return (
    <>
      <div>
        <div className="  p60 process_sec bgc2  " data-scroll-index={2}>
          <div className="container ">
            <div className="row  align-items-center ">
              <div className="col-md-4 order-md-2 mr-auto">
                <img
                  src="/assets/img/mission.png"
                  alt="Payment"
                  className="img-fluid"
                  data-aos="fade-left"
                />
              </div>
              <div className="col-md-5 order-md-1 ml-auto">
                <div className="vision_box">
                  <div className="vision_box_in">
                    <h2 className=" hadding mb-3  " data-aos="fade-up">
                      Our Mission{" "}
                    </h2>
                    <p data-aos="fade-up">
                      The mission of asset plus is committed to empowering
                      individuals and businesses globally through strategic
                      investments in crypto mining, crypto trading, Forex
                      trading, stocks, automobile, e-commerce, real estate and
                      other sectors. Our goal is to create sustainable value,
                      foster economic development, and set new standards of
                      excellence across industries, while prioritizing
                      transparency, innovation, and long-term growth for all our
                      investors.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row   align-items-center">
              <div className="col-md-4  ml-auto">
                <img
                  src="/assets/img/vision.png"
                  alt="Payment"
                  className="img-fluid"
                  data-aos="fade-right"
                />
              </div>
              <div className="col-md-6 mr-auto">
                <div className="vision_box">
                  <div className="vision_box_in" data-aos="fade-left">
                    <h3>Our Vision </h3>
                    <p>
                      The vision of Asset Plus is to become a global leader in
                      diversified investments. We strive to be at the forefront
                      of financial technology, crypto, and e-commerce, setting
                      benchmarks for excellence, sustainability, and growth. Our
                      aim is to empower individuals and businesses worldwide by
                      creating opportunities, driving economic advancement, and
                      building a future where financial success is accessible to
                      all. Through innovation, integrity, and strategic
                      leadership, we envision shaping the future of global
                      commerce and investment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="  p60 process_sec  text-center" data-scroll-index={2}>
          <div className="container ">
            <div className>
              <h5 className=" small_hadding mb-2   position-relative">
                Our process{" "}
              </h5>
              <h2 className=" hadding mb-2  " data-aos="fade-up">
                How To Join
              </h2>
              <p className="op_08">
                {" "}
                Assetplus supports both metamask &amp; trust wallet dapp &amp;
                browser extension. You can register on Assetplus using any
                wallet.{" "}
              </p>
            </div>
            <div className="row mt-5 process_line">
              <i className="bi bi-circle-fill c_fill1" />
              <i className="bi bi-circle-fill c_fill2" />
              <div className="col-md-4 ">
                <div className="process_box">
                  <div className="process_box_img">
                    <img
                      decoding="async"
                      src="/assets/img/p1.jpg"
                      alt=" Payment Gateway"
                    />
                    <span>01</span>
                  </div>
                  {/* <h3>Planning</h3> */}
                  <p className>
                    Maintain USDT And BNB In Your Metamask Or Trust Wallet For
                    Activation And Gas Fee.
                  </p>
                </div>
                <i className="bi bi-caret-right-fill" />
              </div>
              <div className="col-md-4 ">
                <div className="process_box">
                  <div className="process_box_img">
                    <img
                      decoding="async"
                      src="/assets/img/p2.jpg"
                      alt=" Payment Gateway"
                    />
                    <span>02</span>
                  </div>
                  {/* <h3>Implementation  </h3> */}
                  <p className>
                    https:/ Open Referral Link In The DAPP BROWSER
                  </p>
                </div>
                <i className="bi bi-caret-right-fill" />
              </div>
              <div className="col-md-4 ">
                <div className="process_box pb-0">
                  <div className="process_box_img">
                    <img
                      decoding="async"
                      src="/assets/img/p3.jpg"
                      alt=" Payment Gateway"
                    />
                    <span>03</span>
                  </div>
                  {/* <h3>Commence</h3> */}
                  <p className>
                    Invest With Just 100$ Usdt And Start Your Journey With
                    Assetplus.
                  </p>
                </div>
              </div>
            </div>
            <a className="btn mt-4" href="/register">
              Get Started
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
