import React, { useEffect, useState } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { Sidebar } from "../../widgets/Sidebar";
import { getRoi, getRoiDateWise } from "../../services/user";
import { Button } from "./partials/Button";
import toastr from "toastr";
import "react-datepicker/dist/react-datepicker.css";

export const RoiReport = () => {
  const [record, setRecord] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
 

  const columns = [
    {
      key: "Sr No.",
      text: "Sr. No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },

    {
      key: "roi_amount",
      text: "Roi",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{Number(record?.roi_amount).toFixed(4)}</>;
      },
    },
    {
      key: "created_at",
      text: "Date & Time",
      className: "Date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{moment(record.created_at).format("LLL")}</>;
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Download",
    no_data_text: "No user found!",
    // button: {
    //   print: true,
    //   csv: true,
    // },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Search in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const pageChange = (pageData) => {};

  const getTotalRoiHandler = async () => {
    const token = localStorage.getItem("jwtToken");
    let data = {};
    const res = await getRoi(data, token);
    if (res.status) {

      setRecord(res?.userRoi);
    }
  };

  useEffect(() => {
    getTotalRoiHandler();
  }, []);

  const handleSearch = async () => {
    if (startDate > endDate) {
      toastr.error("Start date cannot be later than end date");
      return;
    }
    if (startDate && endDate) {
      try {
        const token = localStorage.getItem("jwtToken");
        const data = { startDate, endDate };
        const res = await getRoi(data, token);

        if (res.status) {
          setRecord(res?.userRoi);
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    } else {
      alert("Please select both start and end dates");
    }
  };
  return (
    <>
      <div className="dash_bar_man">
        <Sidebar />
        <div className="d-md-flex mb-2 mb-md-4 align-items-center">
          <Button />
          <h2 className="in_hadding order-md-1">Roi Report</h2>
        </div>

        <div>
          {/* <h2>Date-Based Search Box</h2>
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          placeholderText="Start Date"
        />
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          placeholderText="End Date"
        />
        <button onClick={handleSearch}>Search</button>
      </div> */}
          {/* <input
            type="date"
            onChange={(e) => setStartDate(e.target.value)}
            placeholder="Start Date"
          />
          <input
            type="date"
            onChange={(e) => setEndDate(e.target.value)}
            placeholder="End Date"
          />
          <button onClick={handleSearch}>Search</button> */}
          {/* 
      <div style={{ marginTop: "20px" }}>
        {record.length > 0 ? (
          <ul>
            {record.map((item, index) => (
              <li key={index}>{item.name} - {item.date}</li>
            ))}
          </ul>
        ) : (
          <p>No results found</p>
        )}
      </div> */}
        </div>

        <div className="main-content">
          <div className="page-content">
            <div className="dash_box">
            <div className="d-flex mb-2 search_box_table">
            <input className="form-control form-control22 mr-3"
              type="date"
              onChange={(e) => setStartDate(e.target.value)}
              placeholder="Start Date"
            />
            <input className="form-control form-control22 mr-3"
              type="date"
              onChange={(e) => setEndDate(e.target.value)}
              placeholder="End Date"
            />
            <button className="btn btn_man_small" onClick={handleSearch}><i class="bi bi-search"></i></button>
            </div>
              <div className="product-list-outer">
                <ReactDatatable
                  config={config}
                  records={record}
                  columns={columns}
                  onPageChange={pageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
