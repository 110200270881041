
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route ,useParams} from "react-router-dom";
import { Home } from "./components/Home/Home";
import { Login } from "./components/auth/Login";
import { Dashboard } from "./components/pages/Dashboard";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ProtectedRoute from "./ProtectedRoute";
import { useAuth } from "./AuthContext";
import { Package } from "./components/pages/Package";
import { PackageReport } from "./components/pages/PackageReport";
import { ReferralReport } from "./components/pages/ReferralReport";
import { Team } from "./components/pages/Team";
import { RoiReport } from "./components/pages/RoiReport";
import { LevelReport } from "./components/pages/LevelReport";
import { Genology } from "./components/pages/Genology";
import { MatchingReport } from "./components/pages/MatchingReport";
import { WithdrawallReport } from "./components/pages/WithdrawallReport";

function App() {
  toastr.options = {
    positionClass: "toast-top-right",
    hideDuration: 500,
    timeOut: 5000,
  };


  const { login } = useAuth();

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");
    if (jwtToken) {
      login();
    }
  }, [login]);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
         <Route path="/register" element={<Login />} />
         <Route path="/register/referral/:referralCode" element={<Login />} />
         <Route
            path="/dashboard"
            element={<ProtectedRoute component={<Dashboard />} />}
          />
          <Route
            path="/package"
            element={<ProtectedRoute component={<Package />} />}
          />
            <Route
            path="/get-package-report"
            element={<ProtectedRoute component={<PackageReport />} />}
          />
           <Route
            path="/get-referral-report"
            element={<ProtectedRoute component={<ReferralReport />} />}
          />
            <Route
            path="/team-report"
            element={<ProtectedRoute component={<Team />} />}
          />
           <Route
            path="/roi-report"
            element={<ProtectedRoute component={<RoiReport />} />}
          />
          <Route
            path="/level-report"
            element={<ProtectedRoute component={<LevelReport />} />}
          />
          <Route
            path="/team-tree"
            element={<ProtectedRoute component={<Genology />} />}
          />
          <Route
            path="/team-tree/:id"
            element={<ProtectedRoute component={<Genology />} />}
          />
          <Route
            path="/matching-report"
            element={<ProtectedRoute component={<MatchingReport />} />}
          />
           <Route
            path="/withdrawal-report"
            element={<ProtectedRoute component={<WithdrawallReport />} />}
          />
          
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
         
        </Routes>
      </Router>
    </div>
  );
}

export default App;
