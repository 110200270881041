import React, { useEffect } from "react";
import { Navbar } from "../../widgets/Navbar";
import { Header } from "../../widgets/Header";
import { AboutUs } from "../pages/AboutUs";
import { Process } from "../pages/Process";
import { Features } from "../pages/Features";
import { Faq } from "../pages/Faq";
import { ContactUs } from "../pages/ContactUs";
import { Footer } from "../../widgets/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../AuthContext";
export const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("jwtToken");

    const redirectTimeout = setTimeout(() => {
      if (token) {
        navigate("/dashboard");
      }
    }, 100);

    return () => clearTimeout(redirectTimeout);
  }, []);
  return (
    <>
      <Navbar />
      <Header />
      <AboutUs />
      <Process />
      <Features />
      <Faq />
      <ContactUs />
      <Footer />
    </>
  );
};
