import {
  getTeamBusinessApi,
  getTreeTeamApi,
  getMatchingApi,
} from "../constant/Api";
import { baseUrl } from "../constant/BaseUrl";
import * as opsService from "./Ops";

const getTeamBusiness = async (data, token) => {
  let result = await opsService.postdata(
    baseUrl + getTeamBusinessApi,
    data,
    token
  );
  return result;
};
const getTreeTeam = async (data, token) => {
  let result = await opsService.postdata(baseUrl + getTreeTeamApi, data, token);
  return result;
};
const getMatching = async (data, token) => {
  let result = await opsService.postdata(baseUrl + getMatchingApi, data, token);
  return result;
};

export { getTeamBusiness, getTreeTeam, getMatching };
