import React, { useState, useEffect } from "react";
import { binanceChainId, bscRpcUrl, explorerUrlBsc } from "../../constant";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
} from "@web3modal/ethers/react";
import toastr from "toastr";
import { useAuth } from "../../AuthContext";
import {
  registerUser,
  loginUser,
  checkReferralCode,
} from "../../services/user";
import { replace, useNavigate, useParams } from "react-router-dom";

import {
  parseUnits,
  formatEther,
  BrowserProvider,
  Contract,
  JsonRpcProvider,
  parseEther,
} from "ethers";

export const Login = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const [reffralCode, setReffralCode] = useState("");
  const { authenticated, login, setWallet_address } = useAuth();
  const [activeTab, setActiveTab] = useState("register");
  const [walletAddress, setWalletAddress] = useState("");
  const navigate = useNavigate();
  const { walletProvider } = useWeb3ModalProvider();
  const projectId = "1d8e553b04494fc86b01065d081d9e93";
  const params = useParams();

  useEffect(() => {
    if (authenticated) {
      navigate("/dashboard");
      login();
    }
  }, []);

  useEffect(() => {
    if (address && isConnected) {
      setWalletAddress(address);
    }
  }, [address, isConnected]);

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");

    const redirectTimeout = setTimeout(() => {
      if (token) {
        navigate("/dashboard");
      } else {
        // navigate(`/`);
      }
    }, 100);

    return () => clearTimeout(redirectTimeout);
  }, []);

  const mainnet = [
    {
      chainId: binanceChainId,
      name: "Binance Smart Chain",
      currency: "BNB",
      explorerUrl: explorerUrlBsc,
      rpcUrl: bscRpcUrl,
    },
  ];

  const metadata = {
    name: "assetplus",
    description: "ukc",
    url: "https://assetplus.com/",
    icons: ["https://assetplus.com/html/images/Logo.png"],
  };

  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: mainnet,
    projectId,
    enableAnalytics: true,
  });

  const getSignerOrProvider = async (needSigner = false) => {
    try {
      if (!isConnected) {
        throw Error("User disconnected");
      }

      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();

      if (needSigner) {
        const signer = await ethersProvider.getSigner();

        return signer;
      }

      return signer;
    } catch (error) {
      throw error;
    }
  };

  const registerHandler = async () => {
    const data = { reffralCode, wallet_address: address };

    const datas = { reffralCode };
    const res = await checkReferralCode(datas);
    if (res.status) {
      const result = await registerUser(data);

      if (result.status) {
        let token = result.token;
        localStorage.setItem("jwtToken", token);
        login();
        toastr.success(result.message);
        setWallet_address(address);
        localStorage.setItem("wallet_address", walletAddress);
        setTimeout(function () {
          navigate("/dashboard", { replace: true });
          // setDisable(false)
        }, 2000);

        return false;
      } else {
        toastr.error(result.message);
      }
    } else {
      toastr.error(res.message);
    }
  };

  const loginUserHandler = async () => {
    try {
      const data = { wallet_address: address };
      if (address) {
        const result = await loginUser(data);


        if (result.status) {
          const token = result.token;
          localStorage.setItem("jwtToken", token);
          localStorage.setItem("wallet_address", address);
          login();
          toastr.success(result.message);
          setWallet_address(address);

          setTimeout(() => {
            navigate("/dashboard", { replace: true });
          }, 2000);
        } else {
          toastr.error(result.message);
        }
      }
    } catch (err) {
      console.log("Login Error:", err);
      toastr.error("An unexpected error occurred. Please try again.");
    }
  };
  useEffect(() => {
    if (params.referralCode && params.referralCode !== "") {
      setReffralCode(params.referralCode);
    } else {
      setReffralCode("");
    }
  }, [params.referralCode]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const handleAutofillCode = () => {
    setReffralCode("AS100000");
  };

  return (
    <>
      <div className="login_box mt-0" id="home">
        <div className="container m-auto">
          <div className="row ">
            <div className="col-md-5 m-auto">
              <div className="blar_box  si login_box_in">
                <div className="text-center washer mb-md-5 mb-4 w170 ">
                  <a href="/">
                    <img
                      src="/assets/img/logo.png"
                      alt="Launchpad"
                      className="img-fluid rotateme"
                    />
                  </a>
                </div>
                {/* <div className="text-center">
                  <ul className="nav tab_login mb-4">
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#Login"
                      >
                        Login
                      </a>
                    </li>
                    <li className="nav-item active show">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#register"
                      >
                        Register
                      </a>
                    </li>
                  </ul>
                </div>
                
                <div className="tab-content">
                  <div className="tab-pane " id="Login">
                    <a
                      // href="/dashboard"
                      onClick={()=>setActClass()}
                      id="login-btn"
                      className="btn btn-action w100 mt-4"
                    >
                      {isConnected ? (
                        <div onClick={loginUserHandler}>Login</div>
                      ) : (
                        <w3m-button balance="hide" />
                      )}
                    </a>
                  </div>
                  <input
                    type="hidden"
                    name="_token"
                    defaultValue="8I0aBYWpYocvfFumabEckokkZWjwKDVDiYS9sNpn"
                    autoComplete="off"
                  />
                  <div className="tab-pane active" id="register">
                    <div className="mb-4 form-group">
                      <label className="text-left w100">Sponser ID</label>
                      <input
                        type="text"
                        name="referral_code"
                        id="registerId"
                        placeholder="Enter Sponser ID"
                        value={reffralCode}
                        className="form-control"
                      />
                      <div className="text-danger" />
                    </div>
                    {isConnected ? (
                      <button
                        id="register-btn"
                        className="btn btn-action w100 mt-4"
                        onClick={registerHandler}
                      >
                        <span>Register</span>
                      </button>
                    ) : (
                      <w3m-button balance="hide" />
                    )}
                  </div>
                </div> */}

                <div className="text-center">
                  <ul className="nav tab_login mb-4">
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          activeTab === "login" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("login")}
                        // href="#Login"
                      >
                        Login
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        // href="#register"
                        className={`nav-link ${
                          activeTab === "register" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("register")}
                      >
                        Register
                      </a>
                    </li>
                  </ul>

                  {/* Tab panes */}
                  <div className="tab-content">
                    {activeTab === "login" && (
                      <div className="tab-pane active" id="Login">
                        <a
                          onClick={loginUserHandler}
                          id="login-btn"
                          className="btn btn-action  mt-4 d-flex   justify-content-center"
                        >
                          {isConnected ? (
                            <div>Login</div>
                          ) : (
                            <w3m-button balance="hide" />
                          )}
                        </a>
                      </div>
                    )}

                    {activeTab === "register" && (
                      <div className="tab-pane active" id="register">
                        <div className="mb-4 form-group">
                          <label className="text-left w100">Sponsor ID</label>
                          <input
                            type="text"
                            name="referral_code"
                            id="registerId"
                            placeholder="Enter Sponsor ID"
                            onChange={(e) => setReffralCode(e.target.value)}
                            value={reffralCode}
                            className="form-control"
                          />
                          <div className="text-danger" />
                        </div>
                        <div className="d-sm-flex text-md-left text-center  justify-content-center">
                        <div className="d-flex justify-content-center">
                          {isConnected ? ( 
                            <button
                              id="register-btn"
                              className="btn btn-action  mt-4"
                              onClick={registerHandler}
                            >
                              <span>Register</span>
                            </button>
                           
                          ) : (
                            <w3m-button balance="hide" />
                          )}
                           </div>
                          <button
                            id="register-btn"
                            className="btn btn-action ml-sm-5 mt-3 mt-sm-0"
                            onClick={handleAutofillCode}
                          >
                            <span> Don't have sponsor id </span>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
