import React from "react";

export const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img
              src="/assets/img/logo.png"
              alt="header-Logo"
              className="logo"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
          >
            <span className="icon-bar">
              <i className="bi bi-justify" />
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav ml-auto line">
              <li className="nav-item">
                <a className="nav-link active" href="#home" data-scroll-nav={1}>
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about" data-scroll-nav={2}>
                  {" "}
                  About Us
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="#blog" data-scroll-nav={3}>
                  {" "}
                  Pages
                </a>
              </li> */}
              {/* <li className="nav-item">
                <a className="nav-link" href="#blog" data-scroll-nav={3}>
                  {" "}
                  Blog
                </a>
              </li> */}
              <li className="nav-item">
                <a className="nav-link" href="#faq" data-scroll-nav={5}>
                  FAQ
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact" data-scroll-nav={6}>
                  Contact
                </a>
              </li>
              <li className="nav-item">
                <a className="btn ml-md-4" href="/register">
                  <span>Get Started</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
