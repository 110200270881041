import React, { useState, useEffect } from "react";
import { Sidebar } from "../../widgets/Sidebar";
import { getTeamBusiness } from "../../services/team";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { Button as Btn } from "./partials/Button";
import { CLOSING } from "ws";

export const Team = () => {
  const [record, setRecord] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  const [levelReport, setlevelReport] = useState([]);
  const [team, setTeam] = useState([]);

  useEffect(() => {
    const getBusinessData = async () => {
      const address = localStorage.getItem("wallet_address");
      const token = localStorage.getItem("jwtToken");
      const data = { address };

      const result = await getTeamBusiness(data, token);

      if (result?.status) {
        setlevelReport(result?.level);

        setTeam(
          result.team.map((d) => ({
            count: d.length,
            total: d.reduce((t, s) => t + Number(s.packageAmount), 0),
            team: d,
          }))
        );
      }
    };
    getBusinessData();
  }, []);

  const output = [];

  for (const level in levelReport) {
    if (levelReport.hasOwnProperty(level)) {
      const count = levelReport[level].length;
      output.push({ [level]: count });
    }
  }

  const handleShow = (data) => {
    setRecord(data?.team);
    setModalShow(true);
  };
  const handleClose = () => {
    setModalShow(false);
  };

  return (
    <>
      <div className="dash_bar_man">
        <Sidebar />

        <div className="d-md-flex mb-2 mb-md-4 align-items-center">
          <Btn />
          <h2 className="in_hadding order-md-1">Team</h2>
        </div>
        <div className="dash_box">
          <div className="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Sr.N.</th>
                  <th>Level</th>
                  <th>Total Team Count </th>
                  <th>Total Team Business</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {team?.map((item, index) => {
                  return (
                    <tr key={index + 1}>
                      <td>{index + 1}</td>
                      <td>{index + 1}</td>
                      <td>{item.count}</td>
                      <td>{item.total}</td>
                      <td>
                        <button
                          className="btn btn_man_small"
                          onClick={() => handleShow(item)}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <Modal
          show={modalShow}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>Team Details</Modal.Title>
            <Button onClick={handleClose} className="btn">
              <i className="bi bi-x-lg"></i>
            </Button>
          </Modal.Header>
          <Modal.Body className="grid-example">
            <div className="scrool_table">
              <table className="table">
                <tr>
                  <th>id</th>
                  <th>Name</th>
                  <th>Register Id</th>
                  <th>Sponser Id</th>
                  <th>Package Amount</th>
                </tr>

                {record?.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{item?.name}</td>
                      <td>{item?.registerId}</td>
                      <td>{item?.sponser_id}</td>
                      <td>{item?.packageAmount}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
          
          </Modal.Footer> */}
        </Modal>
      </div>
    </>
  );
};
